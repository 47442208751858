import { Box } from '@mui/material';
import Page from 'component/new_design/layout/Page';
import { SitesTable } from '../partial/tables/sites/SitesTable';

export default function Dashboard() {
  return (
    <Page title="Sites">
      <Box>these are sites</Box>
      <SitesTable enableSearch />
    </Page>
  );
}
